section {
    height: 100vh;
}

.studentpage-main {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}


.studentpage-body-one,
.studentpage-body-two,
.studentpage-body-three,
.studentpage-body-four,
.studentpage-body-five
{
    height: 100vh;
}

.studentpage-headings {
    z-index: 2;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}


.studentpage-body-one {
    display: flex;
    flex-direction: row;
}

.studentpage-heading {
    font-family: Poppins Heavy;
    font-size: 58px;
    margin-left: 2rem;
    margin-bottom: 0;
    text-align: left;
}

.studentpage-subheading {
    margin-left: 4rem;
    font-size: 32px;
    text-align: left;
    font-family: Poppins Light;
    color: white;
}

.studentpage-list {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100vh;
}

.studentpage-body-two {
    background-color: #D72638;
    display: flex;
    flex-direction: row;
}

.studentpage-body-three {
    background-color: #FFD520;
    display: flex;
    flex-direction: row;
}

.studentpage-body-four {
    display: flex;
    flex-direction: column;
}


.studentpage-one-overlay {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    height: 100%;
}

.studentpage-one-img {
    position: relative;
}

.studentpage-one-arrow {
    margin-top: 5rem;
    height: 40vh;
}

.studentpage-button {
    background-color: white;
    cursor: pointer;
    padding: 1rem;
    border: 2px solid black;
    border-radius: 5px;
    padding: 0.5rem;
    height: 40vh;
    width: 50vw;
    box-shadow: rgba(255, 211, 14, 0.705) 5px 5px 15px;
}

.studentpage-final-heading {
    font-family: Poppins Heavy;
    font-size: 58px;
    margin-left: 2rem;
    margin-bottom: 0;
    text-align: center;
}

.studentpage-directions {
    display: flex;
    flex-direction: row;
    gap: 10rem;
    padding: 8rem;
    justify-content: center;
    align-content: center;
}

.studentpage-dir-box {
    border: 2px solid black;
    background-color: white;
    border-radius: 5px;
    padding: 0.5rem;
    height: 40vh;
    width: 50vw;
}

.studentpage-img-last {
    height: 20vh;
}
