@font-face {
    font-family: 'Poppins Light';
    src: url("../../../fonts/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
    font-family: 'Poppins Heavy';
    src: url("../../../fonts/Poppins/Poppins-Medium.ttf");
}

.header-main {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E03A3E;
    max-height: 4rem;
    width: 100%;
}

.header-main-dropped {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: red;
    max-height: 20rem;
    transition: max-height 0.5s ease-in-out;
}

.header-navbar {
    display: flex;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: right;
    width: 100%;
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.header-navbar-mini {
    display: block;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: 2rem;
}

.header-navbar-mini li {
    list-style: none;
    margin-bottom: 1.5rem;
}

.header-logo {
    height: 3rem;
    margin-right: 0;
    padding: 0.5rem;
}

.header-li {
    list-style: none;
    font-size: 24px;
    text-align: center;
    width: 12rem;
    font-family: Poppins Heavy;
}

.header-li-dropped {
    list-style: none;
    margin-right: 2rem;
    font-size: 30px;
    text-align: center;
    height: 3rem;
    width: 12rem;
    font-family: Poppins Heavy;
}

.header-a-dropped {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease-in-out, font-size 0.3s ease-in-out;
}

.header-a-dropped:hover {
    color: brown;
}

#header-login {
    border: 1px solid white;
    border-radius: 5px;
    width: 6.5rem;
    margin-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    background-color: transparent;
    color: white;
    cursor: pointer;
    font-family: Poppins Heavy;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: color 0.3s ease-in-out;
    @media screen and (max-width: 900px) {
        display: none;
    }
}

#header-login:hover {
    background-color: white;
    color: black;
}

.header-a {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease-in-out, font-size 0.3s ease-in-out;
}

.header-a:hover {
    color: brown;
    font-size: 25px;
}

.header-link-logo {
    transition: none;
}

.header-link-logo:hover {
    font-size: 16px;
}

.header-menu-icon {
    border: 1px solid white;
    padding: 1rem;
    border-radius: 50%;
    margin-right: 1rem;
    color: white;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.header-menu-icon:hover {
    border: 1px solid white;
    padding: 1rem;
    border-radius: 50%;
    margin-right: 1rem;
    color: black;
    background-color: white;
}

.header-menu-icon-dropped-down {
    border: 1px solid white;
    padding: 1rem;
    border-radius: 50%;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    float: right;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

.header-menu-icon-dropped-down:hover {
    border: 1px solid white;
    padding: 1rem;
    border-radius: 50%;
    color: black;
    background-color: white;
}

.header-mini-menu {
    @media screen and (min-width: 900px) {
        display: none;
    }
}


