.footer-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #b22222;
    width: 100%;
}

.footer-navbar {
    display: flex;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 1.5rem;
}

.footer-ul {
    display: flex;
    margin: 0;
    justify-content: flex-end;
}

.footer-li-copyright {
    list-style: none;
    margin-right: 2rem;
    font-size: 16px;
    text-align: center;
    height: 2rem;
    font-family: Poppins Heavy;
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
}

.footer-li {
    list-style: none;
    margin-right: 2rem;
    font-size: 14px;
    text-align: center;
    height: 2rem;
    font-family: Poppins Heavy;
    width: 8rem;
}

.footer-a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    transition: color 0.3s ease-in-out, font-size 0.3s ease-in-out;
}
