.tutorpersonal-main {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
}

.tutorpersonal-page {
    display: flex;
    padding: 2%;
    gap: 2%;
}

.tutorpersonal-tutor-desc {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 25vw;
    padding: 1%;
    border: 1px solid black;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 5px;
}

.tutorpersonal-pfp {
    align-self: center;
    height: 50%;
    width: 80%;
    border-radius: 50%;
    border: 1px solid black;
}

.tutorpersonal-contact-buttons {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    align-items: center;
}

.tutorpersonal-tutor-info {
    display: flex;
    flex-direction: column;
    border-left: 1px solid black;
    width: 75vw;
    padding: 1.25%;
    max-width: 69.5%;
    overflow-wrap: break-word;
}

.tutorpersonal-heading {
    font-size: 28px;
    font-family: Poppins Heavy;
}

.tutorpersonal-subheading {
    font-size: 18px;
    font-family: Poppins Light;
}

.tutorpersonal-schedule {
    display: flex;
}

.tutorpersonal-tag-body {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
}

.tutorpersonal-tag {
    color: white;
    background-color: #D70040;
    margin-left: 0.5rem;
    padding: 0.2rem;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 1%;
}

.tutorpersonal-experience {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5%;
}

.tutorpersonal-row {
    display: flex;
}

.tutorpersonal-ratings-and-review {
    display: flex;
    gap: 3%;
}

.tutorpersonal-ratings {
    display: flex;

}

.tutorpersonal-reviews {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    width: 90%;
}

.tutorpersonal-review {
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}
