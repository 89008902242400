@font-face {
    font-family: 'Poppins Light';
    src: url("../../fonts/Poppins/Poppins-ExtraLight.ttf");
}

@font-face {
    font-family: 'Poppins Heavy';
    src: url("../../fonts/Poppins/Poppins-Medium.ttf");
}

.AboutPage {
    text-align: left;
    padding-top: 4rem;
}
  
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.AboutPage-header {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y: scroll;
}

.AboutPage-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}