.dashboard-profile {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2rem 2rem;
    width: 75vw;
}

.dashboard-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.dashboard-col {
    display: flex;
    flex-direction: column;
}

.dashboard-col-add {
    display: flex;
    gap: 1rem
    /* border-left: 1px solid black;
    margin-right: 1rem; */
}

.dashboard-list-of-courses {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 2rem;
    gap: 0.5rem;
    width: 65vw;
}

.dashboard-tag {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    background: rgba(220, 20, 60, 0.828);
    color: white;
    font-size: 16px;
    border-radius: 10px;
}

.dashboard-add {
    display: flex;
    border: 2px dashed lightgrey;
    width: 9rem;
    border-radius: 20px;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    font-family: Poppins Heavy;
}

.dashboard-delete {
    display: flex;
    border: 2px dashed lightgrey;
    width: 7.5rem;
    border-radius: 20px;
    justify-content: space-between;
    padding: 0.5rem;
    align-items: center;
    font-family: Poppins Heavy;
}

.dashboard-availability {
    display: flex;
    flex-direction: column;
}

.dashboard-row {
    display: flex;
}

.dashboard-submit-changes {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 15px;
    height: 50%;
}

.dashboard-list-of-times {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 1rem;
    gap: 0.5rem;
    width: 25vw;
}

.dashboard-time-tag {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    background: rgba(220, 20, 60, 0.828);
    color: white;
    font-size: 16px;
    border-radius: 10px;
}

.dashboard-booking-times {
    margin-left: 2rem;
}

.dashboard-appts {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2rem 2rem;
    width: 75vw;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.dashboard-appts-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 15px;
    gap: 0.5rem;
    width: 50rem;
    border: 1px solid black;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow:  1em .4em rgba(255, 26, 26, 0.523), -1em 0 .4em rgb(255, 255, 2);
}

.dashboard-modal {
    justify-content: center;
    position: absolute;
    z-index: 2;
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    top: 23%;
    left: 35%;
    width: 30rem;
    height: 10rem;
    box-shadow: 50% 50% 50% black;
}

.dashboard-confirmation {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    width: 90%;
    gap: 5%;
    padding: 2rem;
}

.dashboard-pay-sect {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
}

.dashboard-exp-pay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7.5rem;
    width: 20rem;
    text-align: center;
    border: 5px double black;
    border-radius: 10px;
    font-size: 60px;
    font-family: Poppins Heavy;
    margin: 0;
}

.dashboard-account {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 2rem 2rem;
    width: 75vw;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.dashboard-acc-form {
    display: flex;
    width: 60vw;
    gap: 1rem;
    margin-bottom: 1rem;
}
