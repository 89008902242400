@font-face {
    font-family: 'Poppins Title';
    src: url("../../../fonts/Poppins/Poppins-Regular.ttf");
}

.searchbody-main {
    z-index: 2;
    display: block;
    text-align: center;
    font-size: 32px;
    font-family: Poppins Title;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.searchbody-overlay {
    position: fixed;
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.01);
}


.searchbody-title {
    margin-bottom: 0.1rem;
    text-shadow: black 1px 0 1px;
}

.searchbody-terp {
    color: #E03A3E;
    display: inline-block;
    text-shadow: #FFD520 2px 0 2px;
    @media screen and (max-width: 820px) {
        text-shadow: black 1px 0 4px;
    }
}

.searchbody-net {
    color: #FFD520;
    display: inline-block;
    text-shadow: red 2px 0 2px;
}


.searchbody-subtitle {
    margin-top: 0.2rem;
    text-shadow: black 1px 0 1px;
}

#searchbody-bar {
    font-size: 24px;
    width: 90%;
    border: none;
    outline: none;
    font-family: Poppins Light;
}

#searchbody-bar::placeholder {
    color: black;
    font-family: Poppins Light;
    opacity: 0.5;
}


.searchbody-search-submit {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    border: 0.8px red solid;
    padding: 0.5rem;
    margin-left: auto;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.searchbody-search-submit:hover {
    background-color: red;
    color: white;
}

.searchbody-searchbar {
    margin-top: 8rem;
    display: inline-flex;
    border-radius: 2rem;
    padding-left: 0.6rem;
    border: 1px solid red;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 60%;
    background-color: white;
    box-shadow: rgba(255, 0, 0, 0.35) 0px 5px 15px;
}

.searchbody-instructions {
    margin-top: 0.5rem;
}

.searchbody-red-img {
    position: fixed;
}

.searchbody-yellow-img {
    position: fixed;
    @media screen and (max-width: 820px) {
        
    }
}

.searchbody-black-img {
    position: fixed;
    @media screen and (max-width: 820px) {
        display: none;
    }
}