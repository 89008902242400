.login-main {
    display: flex;
    z-index: 2;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: auto;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.login-overlay {
    position: fixed;
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100vw;
}

.login-bg {
    object-fit: cover;
    width: 100vw;
}

.login-form {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-top: 2%;
    width: 35vw;
    height: 80vh;
    border-radius: 20px;
}

.login-switch {
    margin-top: auto;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-screen-switch {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50px;
    padding: .25rem;
    cursor: pointer;
    width: 15rem;
    height: 2.5rem;
    border: 0.25px solid black;
    background-color: rgba(91, 90, 90, 0.05);
}

.login-screen-switch[switch-toggleRegister="true"] {
    justify-content: flex-end;
}

.login-switch-text[switch-toggleRegister="true"] {
    background: linear-gradient(315deg, #ffff45 0%, #ff5858 74%);
}


.login-switch-text {
    width: 5.5rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    background: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    box-shadow: rgba(255, 0, 0, 0.35) 0px 3px 5px;
    color: white;
}

.login-switch-opposite-text-log {
    position: absolute;
    width: 5.5rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    text-align: center;
    margin-left: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    border-color: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    color: black;
}

.login-switch-opposite-text-reg {
    position: absolute;
    width: 5.5rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    text-align: center;
    margin-right: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    border-color: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    color: black;
}

.login-switch-opposite-text-reg[switch-toggleRegister="false"] {
    color: rgba(91, 90, 90, 0.05);
}

.login-switch-opposite-text-log[switch-toggleRegister="true"] {
    color: rgba(91, 90, 90, 0.05);
}

.login-register-form {
    display: flex;
    flex-direction: column;
    padding: 8%;
}

.login-input {
    margin-bottom: 5%;
    width: 100%;
    height: 15%;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    font-family: Poppins Heavy;
}
.login-password {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 1%;
}

.login-pass-input {
    margin-bottom: 5%;
    width: 95%;
    height: 50%;
    font-size: 20px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    font-family: Poppins Heavy;
}

.login-input::placeholder,
.login-pass-input::placeholder
{
    font-family: Poppins Light;
    font-size: 16px;
}

.login-labels {
    font-size: 20px;
    font-family: Poppins Light;
}

/* --- */

.login-switch-consumer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-screen-switch-consumer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 50px;
    padding: .25rem;
    cursor: pointer;
    width: 15rem;
    height: 2.5rem;
    border: 0.25px solid black;
    background-color: rgba(91, 90, 90, 0.05);
}

.login-screen-switch-consumer[switch-toggleStudent="true"] {
    justify-content: flex-end;
}

.login-switch-text-consumer[switch-toggleStudent="true"] {
    background: linear-gradient(315deg, #ffff45 0%, #ff5858 74%);
}


.login-switch-text-consumer {
    width: 5.5rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    background: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    box-shadow: rgba(255, 0, 0, 0.35) 0px 3px 5px;
    color: white;
}

.login-switch-opposite-text-student {
    position: absolute;
    width: 5.5rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    text-align: center;
    margin-left: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    border-color: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    color: black;
}

.login-switch-opposite-text-tutor {
    position: absolute;
    width: 5.5rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    text-align: center;
    margin-right: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    border-color: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    color: black;
}

.login-switch-opposite-text-tutor[switch-toggleStudent="false"] {
    color: rgba(91, 90, 90, 0.05);
}

.login-switch-opposite-text-student[switch-toggleStudent="true"] {
    color: rgba(91, 90, 90, 0.05);
}

.login-submit {
    width: 15rem;
    height: 2rem;
    border-radius: 50px;
    padding: .1rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: Poppins Heavy;
    font-size: 19px;
    background: linear-gradient(319deg, #ffcb43 0%, #ff6425 37%, #ff0016 100%);
    box-shadow: rgba(255, 0, 0, 0.35) 0px 3px 5px;
    color: white;
    cursor: pointer;
}

.login-center {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.login-loader {
    display: flex;
    margin-top: 50%;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.login-center-success {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-top: 40%;
}

.login-center-failure {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-top: 40%;
}