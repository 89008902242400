.booking-main {
    display: flex;
    flex-direction: column;
    margin-top: 4.4%;    
}

.booking-page {
    display: flex;
    padding-top: 1%;
    padding-left: 3%;
    margin-bottom: 3%;
}

.booking-calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 90vh;
    padding-left: 5%;
}


.booking-signin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 90vh;
    padding-left: 5%;
    font-family: Poppins Heavy;
    font-size: 32px;
    align-items: center;
    margin-bottom: 2%;
    margin-right: 1rem;
}

.booking-times {
    display: flex;
    border-left: 1px solid black;
    padding-left: 3%;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 80%;
}

.booking-time {
    display: flex;
    border: 1px solid black;
    border-radius: 10px;
    padding: 1%;
    color: crimson;
    font-family: Poppins Heavy;
    font-size: 20px;
    gap: 5%;
    height: 7%;
    width: 15rem;
    align-items: center;
    margin-bottom: 2%;
    margin-right: 1rem;
}

.booking-no-times {
    display: flex;
    border-radius: 10px;
    padding: 1%;
    font-family: Poppins Heavy;
    font-size: 32px;
    height: 7%;
    width: 15rem;
    align-items: center;
    margin-bottom: 2%;
    margin-right: 1rem;
}

.booking-confirmation {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    top: 23%;
    left: 35%;
    width: 30rem;
    height: 30rem;
    box-shadow: 50% 50% 50% black;
}

.booking-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.booking-stripe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    font-size: 18px;
    width: 90%;
    gap: 5%;
}

#nameoncard {
    border: none;
    outline: none;
}

.booking-cart {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    overflow-y: scroll;
}