.marketbody-main {
    display: flex;
    flex-direction: row;
    /* align-items: center;
    align-content: center;
    justify-content: center; */
    padding: 4rem;
    margin-top: 5rem;
}

.marketbody-filter {
    flex: 1;
    border-right: 1px solid black;
    box-sizing: border-box;
    padding-right: 1rem;
    margin-right: 1rem;
}

.marketbody-search {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex: 3;
    align-items: center;
    box-sizing: border-box;
}

.marketbody-filter-subheading {
    font-family: Poppins Heavy;
    font-size: 32px;
    text-align: left;
}

.marketbody-tutors-subheading {
    font-family: Poppins Heavy;
    font-size: 32px;
    text-align: left; 
    margin-left: 1rem;
}

.marketbody-searchbar {
    display: inline-flex;
    border-radius: 2rem;
    padding-left: 0.6rem;
    border: 1px solid #D70040;
    justify-content: center;
    align-items: center;
    width: 50rem;
    align-self: center;
    background-color: white;
    box-shadow: #ad697e 0px 0px 15px;
}

.marketbody-searchbar.active {
    animation: borderAnimation 5s;
}

@keyframes borderAnimation {
    0% {
        box-shadow: #ad697e 0px 0px 15px;
    }
    25% {
        box-shadow: #e85c5c 0px 0px 35px;
    }
    50% {
        box-shadow: #ad697e 0px 0px 15px;
    }
    75% {
        box-shadow: #e85c5c 0px 0px 35px;
    }
    100% {
        box-shadow: #ad697e 0px 0px 15px;
    }
}

.marketbody-search-submit {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    border: 0.8px #D70040 solid;
    padding: 0.5rem;
    margin-left: auto;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

#marketbody-bar {
    font-size: 18px;
    width: 100%;
    border: none;
    outline: none;
    font-family: Poppins Light;
}

.marketbody-search-submit:hover {
    background-color: #D70040;
    color: white;
}

.marketbody-form {
    justify-content: center;
    align-items: center;
}

.marketbody-slider {
    width: 80%;
}

.marketbody-filter-headings {
    font-size: 18px;
    font-weight: bold;
}

.marketbody-filter-headings-vp {
    font-size: 18px;
    font-weight: bold;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: 1rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
}


.marketbody-second-body {
    display: flex;
    flex-direction: row;
}

.marketbody-left-second-body {
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    margin-right: 1rem;
}

.marketbody-right-second-body {
    display: flex;
    flex-direction: column;
}

.marketbody-tutor-box {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    border-radius: 15px;
    height: 15rem;
    box-shadow: rgb(88, 78, 78, 0.5) 0px 5px 8px;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.marketbody-tutor-box:hover {
    border: 1px solid black;
    border-radius: 15px;
}

.marketbody-tutor-pic {
    border: 1px solid black;
    border-radius: 5px;
    height: 11rem;
    width: 11rem;
    overflow: hidden;
    margin-right: 1rem;
    justify-content: center;
}

.marketbody-img-pfp {
    width: 100%;
    height: 100%;
}

.marketbody-tutor-desc {
    display: flex;
    flex-direction: column;
    border-right: 1px solid black;
    width: 25rem;
    margin-right: 0.5rem;
}

.marketbody-tag-body {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    flex-wrap: wrap;
}

.marketbody-tag {
    color: white;
    background-color: #D70040;
    margin-left: 0.5rem;
    padding: 0.2rem;
    border-radius: 5px;
    font-size: 12px;
}

.marketbody-elipses {
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    font-weight: bold;
    cursor: pointer;
}

.marketbody-min {
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;
}

.marketbody-tutor-name {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: Poppins Heavy;
}

.marketbody-tutor-classes {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Poppins Light;
}

.marketbody-tutor-info {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    width: 11rem;
}

.marketbody-mini-sections {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.marketbody-mini-bio {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.marketbody-tutor-bio {
    font-size: 16px;
    background-color: #d7004052;
    padding: 0.3rem;
    border-radius: 5px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    width: 95%;
}

.marketbody-tutor-ratings {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
}

.marketbody-tutor-row-ratings {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 1rem;
}

.marketbody-ratings-rating {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 18px;
}

.marketbody-ratings-fee {
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: 18px;
}

.marketbody-ratings-fee-sub {
    margin-right: 0.5rem;
    font-size: 18px;
}

.marketbody-contact-buttons {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.marketbody-heart-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.2rem;
    justify-content: flex-end;
}

.marketbody-heart-icon {
    cursor: pointer;
    margin-bottom: 0.25rem;
    width: 1.25rem;
}

.marketbody-loading-tutors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5rem;
    height: 21rem;
}

.marketbody-no-tutors {
    font-size: 32px;
}